cs.ns('app.ui.composite.directEntry');
app.ui.composite.directEntry.model = cs.clazz({
    extend:   app.ui.composite.abstract.modal.model,
    dynamics: {},
    protos:   {
        create () {
            this.base();

            cs(this).model({
                'data:token':        { value: '', valid: 'string', store: true },
                'data:errorMessage': { value: '', valid: 'string' }
            });
        }
    }
});